import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "reports-and-printed-statements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#reports-and-printed-statements",
        "aria-label": "reports and printed statements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reports and printed statements`}</h1>
    <p>{`For each Medicare and DVA transaction there is a required patient or claimant statement. This statement is available at the time the claim is submitted with a successful lodgement response or stored for later transmission. It`}{`'`}{`s important that patients and claimants be given statements as there are penalties for providers who omit those statements.`}</p>
    <p>{`You can obtain those statements directly via the following method and API endpoint: `}<strong parentName="p">{`GET`}</strong>{`  `}<strong parentName="p">{`{baseurl}/v3/transactions/{transactionId}/claims/{claimId}/pdf`}</strong></p>
    <p>{`This will return a PDF binary of the printed statement.`}</p>
    <p>{`Alternatively, partners can construct their own statements if they comply with Services Australia statement template rules. Speak to Tyro Health partner staff if you wish to proceed with this route.`}</p>
    <p><strong parentName="p">{`Processing `}{`&`}{` payment reports`}</strong></p>
    <p>{`For Bulk Bill and DVA transactions, there are Processing Report and Payment Reports. These two reports are only available after a claim has been processed by Medicare or DVA. These reports will specify processing exceptions and payment remittances for approved claims. A processing and payment report is typically available when the `}<a parentName="p" {...{
        "href": "https://docs.medipass.io/sdk/getting-started/submit/#set-a-transaction-webhook"
      }}>{`healthFundApprovedInvoice Webhook`}</a>{` event has been invoked.`}</p>
    <p>{`Use the following methods and APIs to manually retrieve reports:`}</p>
    <ul>
      <li parentName="ul">{`Processing report: `}<strong parentName="li">{`{baseurl}/v3/businesses/{businessId}/transactions/{transactionId}/processingreport`}</strong></li>
      <li parentName="ul">{`Payment report: `}<strong parentName="li">{`{baseurl}/v3/businesses/{businessId}/transactions/{transactionId}/paymentreport`}</strong></li>
    </ul>
    <p>{`Alternatively, you can fetch a prettyfied processing `}{`&`}{` payment report by using the `}<a parentName="p" {...{
        "href": "https://docs.medipass.io/sdk/sdk-reference/transactions/#sdktransactionsgetpaymentreport"
      }}>{`'`}{`Get payment report`}{`'`}{` method`}</a>{` and `}<a parentName="p" {...{
        "href": "https://docs.medipass.io/sdk/sdk-reference/transactions/#sdktransactionsgetprocessingreport"
      }}>{`'`}{`Get processing report`}{`'`}{` method`}</a>{` from the Transaction SDK. Ref: `}<a parentName="p" {...{
        "href": "https://docs.medipass.io/sdk/sdk-reference/transactions/#sdktransactionsgetpaymentreport"
      }}>{`https://docs.medipass.io/sdk/sdk-reference/transactions/#sdktransactionsgetpaymentreport`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      